const NavigationProjectModel = [
	{
		id: 0,
		name: 'Overview',
		url: '#',
	},
	{
		id: 1,
		name: 'General',
		url: '#',
	},
	{
		id: 2,
		name: 'Task Board',
		url: '#',
	},
	{
		id: 3,
		name: 'Team',
		url: '#',
	},
	{
		id: 4,
		name: 'Services',
		url: '#',
	},
	{
		id: 5,
		name: 'Events',
		url: '#',
	},
	{
		id: 6,
		name: 'Finances',
		url: '#',
	},
	{
		id: 7,
		name: 'Add-Ons',
		url: '#',
	},
	{
		id: 8,
		name: 'Files',
		url: '#',
	},
];

Object.freeze(NavigationProjectModel);

export default NavigationProjectModel;
