import _ from 'lodash';
import http from '../../../../services/httpService';
import { TaskBoardStatusModel } from '../../../../models/TaskBoardModel';

const taskBoardModule = {
	namespaced: true,
	state: {
		taskBoard: [],
		taskboardStatus: _.cloneDeep(TaskBoardStatusModel),
	},
	actions: {
		setTaskBoard(context) {
			http.getTaskboard()
				.then((response) => {
					context.commit('setTaskBoard', response);
				});
		},
	},
	mutations: {
		setTaskBoard(state, payload) {
			state.taskBoard = payload;
		},
	},
	getters: {
		getTaskBoardDetails: (state) => state.taskBoard,
		getTaskboardStatus: (state) => state.taskboardStatus,
	},
};

export default taskBoardModule;
