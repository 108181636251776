import axios from 'axios';
import endpoints from '../endpoints';

const http = {
	getUser(id) {
		return axios.get(endpoints.getUser(id));
	},
	async getTaskboard() {
		try {
			const response = await axios.get(endpoints.getTaskBoard);
			console.log(response.data); // eslint-disable-line no-console
			return response.data;
		} catch (error) {
			return error;
		}
	},
	async getTaskboardComments(id) {
		try {
			const response = await axios.get(endpoints.getTaskboardComment(id));
			return response.data;
		} catch (error) {
			return error;
		}
	},
	async getTaskboardSubtasks(id) {
		try {
			const response = await axios.get(endpoints.getTaskboardSubtask(id));
			return response.data;
		} catch (error) {
			return error;
		}
	},
};

export default http;
