<template>
	<v-app-bar
		app
		class="navigation-container"
		color="white"
		light
	>
		<div class="d-flex align-center">
			<ul class="d-flex">
				<li v-for="menuItem in getNavProject" :key="menuItem.id" class="pa-5">
					<span>{{ menuItem.name }}</span>
				</li>
			</ul>
		</div>

		<v-spacer></v-spacer>
	</v-app-bar>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Navigation',
	components: {},
	data: () => ({
		//
	}),
	computed: {
		...mapGetters('navigationModule', ['getNavProject']),
	},
};
</script>

<style lang="scss" src="./scss/styles.scss"/>
