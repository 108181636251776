import _ from 'lodash';

/** Data Models * */
import NavigationProjectModel from '../../../models/NavigationProjectModel';

const index = {
	namespaced: true,
	state: {
		navProject: _.cloneDeep(NavigationProjectModel),
	},
	actions: {},
	mutations: {},
	getters: {
		getNavProject: (state) => state.navProject,
	},
};

export default index;
