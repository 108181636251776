import http from '../../../../services/httpService';

const commentsModule = {
	namespaced: true,
	state: {
		taskboardComments: {},
	},
	actions: {
		setTaskboardComments(context, payload) {
			http.getTaskboardComments(payload)
				.then((response) => {
					context.commit('setTaskboardComments', response);
				});
		},
	},
	mutations: {
		setTaskboardComments(state, payload) {
			// eslint-disable-next-line no-param-reassign
			state.taskboardComments = payload;
		},
	},
	getters: {
		getTaskboardComments: (state) => state.taskboardComments,
	},
};

export default commentsModule;
