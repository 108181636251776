import http from '../../../../services/httpService';

const subTasksModule = {
	namespaced: true,
	state: {
		taskboardSubTasks: {},
	},
	actions: {
		setTaskboardSubTasks(context, payload) {
			http.getTaskboardSubtasks(payload).then((response) => {
				context.commit('setTaskboardSubTasks', response);
			});
		},
	},
	mutations: {
		setTaskboardSubTasks(state, payload) {
			// eslint-disable-next-line no-param-reassign
			state.taskboardSubTasks = payload;
		},
	},
	getters: {
		getTaskboardSubTasks: (state) => state.taskboardSubTasks,
	},
};

export default subTasksModule;
