import _ from 'lodash';

/** Data Models * */
import UserModel from '../../../models/UserModel';

const userModule = {
	namespaced: true,
	state: {
		user: _.cloneDeep(UserModel),
	},
	actions: {},
	mutations: {},
	getters: {
		getUser: (state) => state.user,
	},
};

export default userModule;
