const endpoints = {
	getUser(id) {
		return `api/user/${id}`;
	},
	getMenu: 'api/navigation',
	getTaskBoard: 'taskBoard.json',
	getTaskboardComment(id) {
		return `comment${id}.json`;
	},
	getTaskboardSubtask(id) {
		return `subtask${id}.json`;
	},
};

export default endpoints;
