export const TaskBoardModel = [
	{
		title: 'Backlog',
		status: 'Backlog',
		tasks: [
			{
				taskId: 202011,
				taskName: 'Design shopping cart dropdown',
				assigneeName: 'Boyko Velkov',
				assigneAvatar: 'https://s3.eu-central-1.amazonaws.com/mbox.succevo/prod/photos/contacts/347876f19b646c5124ee5__succevo.jpg',
				taskDesciption: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
				category: 'Development >> Code Review',
				reporterName: 'DC Bhavana',
				createdAt: '21 December',
				priority: 'High',
				label: ['Frontend', 'Backend'],
				services: '',
				additionalAssignee: '',
				dueDate: '19/01/2022',
				milestone: false,
				multiTask: false,
				parentTasks: {
					parentTaskId: 203021,
					parentTaskName: 'Design of taskboard edit UI',
				},
				estimatedTime: '',
				totalTimeSpend: '',
				rca: '',
				commentsCount: 20,
				subTaskCount: 5,
			},
		],
	},
];
Object.freeze(TaskBoardModel);

export const TaskBoardStatusModel = [
	{
		title: 'Status',
		icon: 'mdi-pencil-outline',
		status: [
			{
				title: 'Backlog',
				icon: 'fas fa-box',
			},
			{
				title: 'Requested',
				icon: 'mdi-hand-back-right-outline',
			},
			{
				title: 'In Progress',
				icon: 'mdi-wrench-outline',
			},
			{
				title: 'Done',
				icon: 'mdi-check-circle-outline',
			},
			{
				title: 'Tested',
				icon: 'mdi-eye',
			},
			{
				title: 'Archive',
				icon: 'mdi-archive-outline',
			},
		],
	},
	{
		title: 'Remove all',
		icon: 'mdi-delete-outline',
	},
];
Object.freeze(TaskBoardStatusModel);
