import Vue from 'vue';
import Vuex from 'vuex';

/** * Modules ** */
import userModule from './modules/userModule/index';
import navigationModule from './modules/navigationModule/index';
import taskBoardModule from './modules/taskBoard/taskBoardModule/index';
import commentsModule from './modules/taskBoard/commentsModule/index';
import subTasksModule from './modules/taskBoard/subTasksModule/index';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		userModule,
		navigationModule,
		taskBoardModule,
		commentsModule,
		subTasksModule,
	},
});
