const UserModel = [{
	id: '0',
	first_name: 'First Name',
	last_name: 'Last Name',
	role: 'Role',
}];

Object.freeze(UserModel);

export default UserModel;
