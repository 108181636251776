<template>
	<v-app>
		<navigation/>
		<v-main>
			<router-view/>
		</v-main>
	</v-app>
</template>

<script>
import Navigation from './components/Navigation/index.vue';

export default {
	name: 'App',
	components: {
		Navigation,
	},
	data: () => ({
		//
	}),
};
</script>
<style lang="scss">
/** Import Roboto Fonts **/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
/** Import Material Icons **/
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');
</style>
